@import "palettes";
@import "theme";
@import "mixins";

pre,
code,
pre *,
code * {
  font-family: $mono;
}

// inline code
code.highlighter-rouge {
  padding: 2px 6px;
  background: $gray-800;
  color: $white;
  line-height: $spacing;
}

// code block
div.highlighter-rouge {
  margin: 40px 0;
  @include trim-v-margins;
  background: $gray-500;
  overflow-x: auto;
  overflow-y: auto;

  pre.highlight {
    display: inline-block;
    margin: 0;
    padding: 20px;
  }
}

// code block syntax highlighting
// based on tomorrow night eighties from:
// https://github.com/StylishThemes/Syntax-Themes/tree/master/pygments/css-github
// check out more pretty themes:
// https://stylishthemes.github.io/Syntax-Themes/pygments/
pre.highlight {
  color: $white;

  // Comment
  .c {
    color: $gray-500;
  }
  // Error
  .err {
    color: $pink-200;
  }
  // Keyword
  .k {
    color: $purple-200;
  }
  // Literal
  .l {
    color: $orange-200;
  }
  // Name
  .n,
  .h {
    color: $gray-50;
  }
  // Operator
  .o {
    color: $blue-200;
  }
  // Punctuation
  .p {
    color: $gray-50;
  }
  // Comment.Multiline
  .cm {
    color: $gray-500;
  }
  // Comment.Preproc
  .cp {
    color: $gray-500;
  }
  // Comment.Single
  .c1 {
    color: $gray-500;
  }
  // Comment.Special
  .cs {
    color: $gray-500;
  }
  // Generic.Deleted
  .gd {
    color: $pink-200;
  }
  // Generic.Emph
  .ge {
    font-style: italic;
  }
  // Generic.Heading
  .gh {
    color: $gray-50;
    font-weight: bold;
  }
  // Generic.Inserted
  .gi {
    color: $green-200;
  }
  // Generic.Prompt
  .gp {
    color: $gray-500;
    font-weight: bold;
  }
  // Generic.Strong
  .gs {
    font-weight: bold;
  }
  // Generic.Subheading
  .gu {
    color: $blue-200;
    font-weight: bold;
  }
  // Keyword.Constant
  .kc {
    color: $purple-200;
  }
  // Keyword.Declaration
  .kd {
    color: $purple-200;
  }
  // Keyword.Namespace
  .kn {
    color: $blue-200;
  }
  // Keyword.Pseudo
  .kp {
    color: $purple-200;
  }
  // Keyword.Reserved
  .kr {
    color: $purple-200;
  }
  // Keyword.Type
  .kt {
    color: $yellow-500;
  }
  // Literal.Date
  .ld {
    color: $green-200;
  }
  // Literal.Number
  .m {
    color: $orange-200;
  }
  // Literal.String
  .s {
    color: $green-200;
  }
  // Name.Attribute
  .na {
    color: $blue-200;
  }
  // Name.Builtin
  .nb {
    color: $gray-50;
  }
  // Name.Class
  .nc {
    color: $yellow-500;
  }
  // Name.Constant
  .no {
    color: $pink-200;
  }
  // Name.Decorator
  .nd {
    color: $blue-200;
  }
  // Name.Entity
  .ni {
    color: $gray-50;
  }
  // Name.Exception
  .ne {
    color: $pink-200;
  }
  // Name.Function
  .nf {
    color: $blue-200;
  }
  // Name.Label
  .nl {
    color: $gray-50;
  }
  // Name.Namespace
  .nn {
    color: $yellow-500;
  }
  // Name.Other
  .nx {
    color: $blue-200;
  }
  // Name.Property
  .py {
    color: $gray-50;
  }
  // Name.Tag
  .nt {
    color: $blue-200;
  }
  // Name.Variable
  .nv {
    color: $pink-200;
  }
  // Operator.Word
  .ow {
    color: $blue-200;
  }
  // Text.Whitespace
  .w {
    color: $gray-50;
  }
  // Literal.Number.Float
  .mf {
    color: $orange-200;
  }
  // Literal.Number.Hex
  .mh {
    color: $orange-200;
  }
  // Literal.Number.Integer
  .mi {
    color: $orange-200;
  }
  // Literal.Number.Oct
  .mo {
    color: $orange-200;
  }
  // Literal.String.Backtick
  .sb {
    color: $green-200;
  }
  // Literal.String.Char
  .sc {
    color: $gray-50;
  }
  // Literal.String.Doc
  .sd {
    color: $gray-500;
  }
  // Literal.String.Double
  .s2 {
    color: $green-200;
  }
  // Literal.String.Escape
  .se {
    color: $orange-200;
  }
  // Literal.String.Heredoc
  .sh {
    color: $green-200;
  }
  // Literal.String.Interpol
  .si {
    color: $orange-200;
  }
  // Literal.String.Other
  .sx {
    color: $green-200;
  }
  // Literal.String.Regex
  .sr {
    color: $green-200;
  }
  // Literal.String.Single
  .s1 {
    color: $green-200;
  }
  // Literal.String.Symbol
  .ss {
    color: $green-200;
  }
  // Name.Builtin.Pseudo
  .bp {
    color: $gray-50;
  }
  // Name.Variable.Class
  .vc {
    color: $pink-200;
  }
  // Name.Variable.Global
  .vg {
    color: $pink-200;
  }
  // Name.Variable.Instance
  .vi {
    color: $pink-200;
  }
  // Literal.Number.Integer.Long
  .il {
    color: $orange-200;
  }
}
